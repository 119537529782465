import React from 'react'
import { MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class AltList extends React.Component {
  render() {
    return (
      <MDBRow
        className={`about-item align-items-center ${
          this.props.placement === 'right' ? 'flex-row-reverse' : ''
        }`}
      >
        <MDBCol lg="6">
         <GatsbyImage image={this.props.image} alt={this.props.imageAltText} className="rounded" />
        </MDBCol>

        <MDBCol lg="6">
          <div className="about-content">
            <h2
              className={
                this.props.titleClass != null
                  ? this.props.titleClass
                  : 'font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue'
              }
            >
              {this.props.title}
            </h2>
              {this.props.subtitle != null && (
                <h4
                  className={
                    this.props.subtitleClass != null
                      ? this.props.subtitleClass
                      : 'font-alt font-w-700 letter-spacing-1 mb-4 title-xs-large title-extra-large text-grayish-blue'
                  }
                >
                  {this.props.subtitle}
                </h4>
              )}

              {this.props.bullets.map((bullet, index) => {
                return (
                  <ul key={index} className="list-unstyled font-w-400 text-medium">
                    <MDBAnimation reveal type="fadeInUp" delay="0.3s">
                      <li>{bullet.text}</li>
                    </MDBAnimation>
                  </ul>
                )
              })}

            <div
              className="mt-3 font-w-400 text-medium"
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />

            {this.props.link ? (
              this.props.link.charAt(0) == '/' ? (
                <Link
                  to={this.props.link}
                  className={`mt-4 ${this.props.colour}`}
                  aria-label={this.props.title}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              ) : (
                <a
                  href={this.props.link}
                  className={`mt-4 ${this.props.colour}`}
                  target="_blank"
                  rel="noopener"
                  aria-label={this.props.title}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              )
            ) : null}
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default AltList
